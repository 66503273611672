import { useMemo } from 'react';
import {
  type GetProfessionsListWithCoursesQueryResult,
  useGetProfessionsListWithCoursesQuery,
} from '@/controllers/profession/generated/getProfessionsListWithCourses.query.generated';
import { type ProfessionsListWithCoursesFragment } from '@/controllers/profession/generated/professionsListWithCourses.fragment.generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { type CourseType } from '@/controllers/graphql/generated';
import { excludeEntitiesFromList } from '@/controllers/courses/courses.utils/excludeEntitiesFromList';
import { EMPTY_ARRAY } from '@/constants';

type Args = {
  courseTypes: CourseType[];
};

type Output = [
  ProfessionsListWithCoursesFragment[],
  GetProfessionsListWithCoursesQueryResult,
];

export const useGetProfessionsListWithCourses = (args: Args): Output => {
  const {
    courseTypes,
  } = args;

  const { subDomain, language } = useSubDomainContext();

  const queryResult = useGetProfessionsListWithCoursesQuery({
    variables: {
      domain: subDomain,
      lang: language,
      courseTypes,
    },
  });

  const content = useMemo(
    () => {
      const result = queryResult.data?.professionsListWithCourses
        ?? EMPTY_ARRAY;

      return excludeEntitiesFromList(result);
    },
    [queryResult.data?.professionsListWithCourses],
  );

  return [content, queryResult];
};
