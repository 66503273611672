import { useMemo } from 'react';
import {
  CourseStatus,
  CourseType,
} from '@/controllers/graphql/generated';
import { useCoursesList } from '@/controllers/courses/courses.hooks/useCoursesList';
import { type CourseListFragment } from '@/components/platform/Learn/graphql/generated/courseList.fragment.generated';
import { EMPTY_ARRAY } from '@/constants';

type GroupedCoursesList = {
  partTimeCourses?: CourseListFragment[];
  fullTimeCourses?: CourseListFragment[];
};

export const useGroupedCoursesList = (): GroupedCoursesList => {
  const [courses] = useCoursesList({
    statuses: CourseStatus.Active,
    types: [CourseType.Postpaid, CourseType.Prepaid],
  });

  const [partTimeCourses, fullTimeCourses] = useMemo(
    () => courses.reduce<CourseListFragment[][]>(
      ([parttime = EMPTY_ARRAY, fulltime = EMPTY_ARRAY], course) => (
        course.type === CourseType.Prepaid
          ? [[...parttime, course], fulltime]
          : [parttime, [...fulltime, course]]),
      [],
    ),
    [courses],
  );

  return {
    partTimeCourses,
    fullTimeCourses,
  };
};
